import { QueryClient } from '@tanstack/react-query'
import { formatISO, startOfDay, subMonths, subWeeks, subYears } from 'date-fns'
import { original, produce } from 'immer'
import { WritableDraft } from 'immer/dist/types/types-external'
import mutableMerge from 'lodash/mergeWith'
import {
	filter,
	find,
	forEachObjIndexed,
	includes,
	indexBy,
	indexOf,
	mergeDeepLeft,
	omit,
	reduce,
	reject,
	uniqBy,
	where,
} from 'ramda'
import { entries, groupBy, isDeepEqual, prop, sortBy } from 'remeda'
import {
	BehaviorSubject,
	combineLatest,
	connectable,
	merge,
	of,
	Subject,
} from 'rxjs'
import {
	combineLatestWith,
	distinctUntilChanged,
	pairwise,
	filter as rxjsFilter,
	map as rxjsMap,
	switchMap,
	tap,
	throttleTime,
} from 'rxjs/operators'
import { Socket } from 'socket.io-client'
import { GetState } from 'zustand'

import { createEmptyPagePaginatedResult } from '../../api-adapter/api-adapter-utils'
import { BOARD_VIEW, BoardView, events, optionIds } from '../../constants'
import {
	AndCriteria,
	Filter,
	IdentityCriteria,
	IsActiveCriteria,
	mapTasksToAssigneeFilters,
	mapTasksToOwnerFilters,
	mapTasksToPriorityFilters,
	mapTasksToStatusFilters,
	mapTasksToTagsFilters,
	mapTasksToWorkflowFilters,
	OrCriteria,
} from '../../filters'
import { isInactive } from '../../helpers/taskStatus'
import {
	AssigneeTreeTask,
	BoardStrategy,
	isParentTreeTask,
	OwnerTreeTask,
	ParentTreeTask,
	StatusTreeTask,
	Tree,
	TreeTask,
	WorkflowTreeTask,
} from '../../projects/projectsTypes'
import {
	assigneeStrategy,
	ownerStrategy,
	parentStrategy,
	statusStrategy,
	workflowStrategy,
} from '../../projects/strategies'
import { createSocketObservable } from '../../socketUtils'
import {
	InactiveTasksRange,
	ObjectIndex,
	PlayerOptions,
	ProjectView,
	SharedTask,
	Slice,
	SliceFilterActions,
	Task,
	TaskMoveSocketValue,
	TaskPosition,
	TeardownFn,
	UserFocus,
	WorkflowIndex,
	WorkflowStep,
} from '../../types'
import { ApiListResult, isApiListResult } from '../api/baseApiAdapter'
import { MoveTaskAction, UpdateTaskAction } from '../createActions'
import { createRemoveTaskFromQueryCache } from '../mutations/tasks'
import {
	addTaskSubject,
	moveTaskSubject,
	removeTaskSubject,
	updateTaskSubject,
} from '../observables'
import {
	createQueryObservable,
	createTaskDetailQuery,
	fetchPlayerOptions,
	playerKeys,
	taskKeys,
	workflowKeys,
} from '../queries'
import { isInfiniteData } from '../store-type-guards'
import { AppState, MutatedAppState, MutatedSetState } from '../store-types'
import {
	createZustandObservable,
	getServiceFromState,
	mapExistingTasks,
	userOptionIds,
} from '../utils'
import {
	isDescendantOf,
	isParentInTree,
	isTopLevelProject,
} from '../utils/tree'
import { isProjectListParams, projectKeys } from './projects-keys'
import { createProjectDetailQuery } from './projects-queries'
import { ProjectDetail } from './projects-types'
import { createUsersFocusObservable } from './usersFocus'

type OrphanResolver = (
	taskById: Record<string, TreeTask>,
	filteredTasks: TreeTask[]
) => TreeTask[]

type StrategyMap = {
	assignee: BoardStrategy<AssigneeTreeTask>
	owner: BoardStrategy<OwnerTreeTask>
	parent: BoardStrategy<ParentTreeTask>
	status: BoardStrategy<StatusTreeTask>
	workflow: BoardStrategy<WorkflowTreeTask>
}

const strategyByBoardView: StrategyMap = {
	[BOARD_VIEW.ASSIGNEE]: assigneeStrategy,
	[BOARD_VIEW.OWNER]: ownerStrategy,
	//[BOARD_VIEW.PARENT]: compactParentStrategy,
	[BOARD_VIEW.PARENT]: parentStrategy,
	[BOARD_VIEW.STATUS]: statusStrategy,
	[BOARD_VIEW.WORKFLOW]: workflowStrategy,
}

const isBoardView = (view: string): view is BoardView =>
	includes(view, Object.values(BOARD_VIEW))

const identityOrphanResolver: OrphanResolver = (_, filteredTasks) =>
	filteredTasks
const identityCriteria = new IdentityCriteria()
const isActiveCriteria = new IsActiveCriteria()
const identityFilter = new Filter('identity')
const createFilterTasks = (
	orphanResolver = identityOrphanResolver,
	selectedFilter: Filter = identityFilter,
	inactiveTasksRangeId: InactiveTasksRange = 'none'
) => {
	const filterCriteria = selectedFilter.criteria
	const activeCriteria =
		inactiveTasksRangeId === 'none' ? isActiveCriteria : identityCriteria

	const criteria = new AndCriteria([filterCriteria, activeCriteria])

	return (tasks: TreeTask[]) =>
		orphanResolver(
			indexBy(prop('id'), tasks),
			filter(criteria.check, tasks)
		)
}

const getBoardView = (
	draft: WritableDraft<AppState>,
	currentBoardView?: string | null
) => {
	const hasWorkflow = projectHasChildWorkflow(draft)
	const boardView = currentBoardView || draft.projects.board.view
	return !hasWorkflow && boardView === 'workflow' ? 'parent' : boardView
}

export const getInactiveFrom = (
	inactiveTasksRange: InactiveTasksRange
): string => {
	switch (inactiveTasksRange) {
		case 'today':
			return formatISO(startOfDay(Date.now()))
		case 'week':
			return formatISO(startOfDay(subWeeks(Date.now(), 1)))
		case 'month':
			return formatISO(startOfDay(subMonths(Date.now(), 1)))
		case 'year':
			return formatISO(startOfDay(subYears(Date.now(), 1)))
		default:
			return 'none'
	}
}

const getInactiveTasksRange = (
	queryClient: QueryClient
): InactiveTasksRange => {
	const playerOptions = queryClient.getQueryData<PlayerOptions>(
		playerKeys.options()
	)
	return playerOptions && playerOptions[optionIds.INACTIVE_TASKS_RANGE_KEY]
		? (playerOptions[
				optionIds.INACTIVE_TASKS_RANGE_KEY
			] as InactiveTasksRange)
		: 'none'
}

const makeGetTask = (queryClient: QueryClient) => (taskId: string) =>
	queryClient.getQueryData<Task>(taskKeys.detail(taskId))

const projectHasChildWorkflow = (draft: WritableDraft<AppState>) => {
	const projectId = draft.projects.selected.projectId
	const project = projectId
		? draft.queryClient.getQueryData<Task>(taskKeys.detail(projectId))
		: null
	return Boolean(project?.workflowData?.childId)
}

const rebuildFilters = (
	set: MutatedSetState,
	get: GetState<AppState>,
	descendants: ProjectDetail['descendants']
) => {
	if (!descendants) {
		return
	}

	const tasks = mapExistingTasks(get().queryClient, descendants)

	set((draft) => {
		draft.projects.selected.filter.assignee =
			mapTasksToAssigneeFilters(tasks)
		draft.projects.selected.filter.owner = mapTasksToOwnerFilters(tasks)
		draft.projects.selected.filter.priority =
			mapTasksToPriorityFilters(tasks)
		draft.projects.selected.filter.status = mapTasksToStatusFilters(tasks)
		draft.projects.selected.filter.tags = mapTasksToTagsFilters(tasks)
		draft.projects.selected.filter.workflow =
			mapTasksToWorkflowFilters(tasks)
	})
}

const combineFilters = (filters: Filter[]) => {
	const pairedFilters = entries(groupBy(filters, prop('type')))

	// Use `or` filter within same type
	const newFilters = reduce(
		(acc, filterPair) => {
			const [, filters] = filterPair as [string, Filter[]]
			const criteria = filters.map(prop('criteria'))
			acc.push(new Filter('or', { criteria: new OrCriteria(criteria) }))
			return acc
		},
		[] as Filter[],
		pairedFilters
	)

	// Use `and` filter within accross different types
	return newFilters.length > 0
		? new Filter('and', {
				criteria: new AndCriteria(newFilters.map(prop('criteria'))),
			})
		: identityFilter
}

const normaliseTreeTask = (task: Task | SharedTask): TreeTask => {
	const data = {
		childSortOrder: [],
		isMinimised: false,
		isParent: true, // Shared tasks are usually parents
		...task,
	}

	data.childSortOrder = data.childSortOrder || []

	return data
}

const rebuildTree = (set: MutatedSetState) => {
	// performance.mark('beginRebuildTree')
	set((draft) => {
		const projectId = draft.projects.selected.projectId

		const originalDraft = original(draft)
		const queryClient = (
			originalDraft ? originalDraft.queryClient : draft.queryClient
		) as QueryClient
		const inactiveTasksRange = getInactiveTasksRange(queryClient)
		const inactiveFrom = getInactiveFrom(inactiveTasksRange)
		const data = projectId
			? queryClient.getQueryData<ProjectDetail>(
					projectKeys.detail(projectId, { inactiveFrom })
				)
			: null

		if (!data) {
			return
		}

		const projectView = draft.projects.view

		const strategy =
			projectView === 'board' && isBoardView(draft.projects.board.view)
				? strategyByBoardView[draft.projects.board.view]
				: parentStrategy

		if (!strategy) {
			return
		}

		const getTask = makeGetTask(queryClient)
		const ancestorOrNull: Task | SharedTask | null =
			getTask(data.ancestor || '') || null

		// performance.mark('beginMapExistingTasks')
		const descendants = mapExistingTasks(queryClient, data.descendants).map(
			normaliseTreeTask
		)
		// performance.mark('endMapExistingTasks')

		if (!ancestorOrNull) {
			return
		}

		const ancestor = normaliseTreeTask(ancestorOrNull)

		const filterResolver = strategy.filterResolver || identityOrphanResolver
		// performance.mark('beginCombineFilters')
		// TODO: input other filter values which useProjectFilter uses
		const selectedFilter = combineFilters(
			draft.projects.selected.filter.selected
		)
		// performance.mark('endCombineFilters')

		// performance.mark('beginCreateFilterTasks')
		const filterTasks = createFilterTasks(
			filterResolver,
			selectedFilter,
			inactiveTasksRange
		)
		// performance.mark('endCreateFilterTasks')

		let tree: Tree<TreeTask | WorkflowStep>
		switch (draft.projects.board.view) {
			case 'workflow': {
				const workflowById =
					queryClient.getQueryData<WorkflowIndex>(
						workflowKeys.list()
					) || {}
				const workflowId = ancestorOrNull?.workflowData?.childId
				const workflow =
					workflowById && workflowId && workflowById[workflowId]
						? workflowById[workflowId]
						: null
				tree = strategy.buildTree(
					{ type: 'workflow', ...ancestor },
					filterTasks(descendants),
					workflow
				)
				break
			}

			default:
				// performance.mark('beginStrategyBuildTree')
				tree = strategy.buildTree(ancestor, filterTasks(descendants))
				// performance.mark('endStrategyBuildTree')
				break
		}

		// performance.mark('beginMutableMerge')
		mutableMerge(
			draft.projects.selected.tree,
			tree,
			(objValue, srcValue) => {
				if (Array.isArray(objValue)) {
					if (isDeepEqual(objValue, srcValue)) {
						return objValue
					} else {
						return srcValue
					}
				}
			}
		)
		// performance.mark('endMutableMerge')

		// performance.mark('endRebuildTree')
		// performance.measure(
		// 	'measureRebuildTree',
		// 	'beginRebuildTree',
		// 	'endRebuildTree'
		// )
		// performance.measure(
		// 	'measureStrategyBuildTree',
		// 	'beginStrategyBuildTree',
		// 	'endStrategyBuildTree'
		// )
		// performance.measure(
		// 	'measureMapExistingTasks',
		// 	'beginMapExistingTasks',
		// 	'endMapExistingTasks'
		// )
		// performance.measure(
		// 	'measureCombineFilters',
		// 	'beginCombineFilters',
		// 	'endCombineFilters'
		// )
		// performance.measure(
		// 	'measureCreateFilterTasks',
		// 	'beginCreateFilterTasks',
		// 	'endCreateFilterTasks'
		// )
		// performance.measure(
		// 	'measureMutableMerge',
		// 	'beginMutableMerge',
		// 	'endMutableMerge'
		// )
		// const rebuildMeasurement = performance
		// 	.getEntriesByName('measureRebuildTree')
		// 	.at(-1)
		// const strategyBuildMeasurement = performance
		// 	.getEntriesByName('measureStrategyBuildTree')
		// 	.at(-1)
		// const mapExistingTasksMeasurement = performance
		// 	.getEntriesByName('measureMapExistingTasks')
		// 	.at(-1)
		// const combineFiltersMeasurement = performance
		// 	.getEntriesByName('measureCombineFilters')
		// 	.at(-1)
		// const createFilterTasksMeasurement = performance
		// 	.getEntriesByName('measureCreateFilterTasks')
		// 	.at(-1)
		// const mutableMergeMeasurement = performance
		// 	.getEntriesByName('measureMutableMerge')
		// 	.at(-1)
		// const refine = ({ name, duration }) => ({ name, duration })
		// console.table([
		// 	refine(rebuildMeasurement),
		// 	refine(strategyBuildMeasurement),
		// 	refine(mapExistingTasksMeasurement),
		// 	refine(combineFiltersMeasurement),
		// 	refine(createFilterTasksMeasurement),
		// 	refine(mutableMergeMeasurement),
		// ])
	})
}

// TODO: move shared code into central place
const createTaskMoveSocket = (socket: Socket) =>
	createSocketObservable<TaskMoveSocketValue>(socket, events.TASK_MOVE)
const createTaskUpdateSocket = (socket: Socket) =>
	createSocketObservable<{ id: Task['id'] } & Partial<Task>>(
		socket,
		events.TASK_UPDATE
	)

const createAddTaskToProjects =
	(set: MutatedSetState, get: GetState<AppState>) =>
	(task: Task, position?: TaskPosition) => {
		const queryClient = get().queryClient

		set((draft) => {
			if (!draft.player.id) {
				throw new Error('Player ID not found')
			}

			if (isTopLevelProject(task)) {
				if (task.isStarred) {
					draft.projects.overview.starred.push(task.id)
				} else if (task.ownerId === draft.player.id) {
					draft.projects.overview.owned.push(task.id)
				} else {
					draft.projects.overview.shared.push(task.id)
				}
			}
		})

		// Update project list queries
		const queries = queryClient.getQueriesData(projectKeys.lists())
		for (const [queryKey, data] of queries) {
			if (!isProjectListParams(queryKey[2])) {
				return
			}

			const addTaskToApiListResult = (
				task: Task,
				position?: TaskPosition
			) =>
				produce<ApiListResult<Task>>((draft) => {
					const items = draft?.items
					if (!items) {
						return
					}

					// Check that we're not adding a duplicate task
					if (items.find((t) => t.id === task.id)) {
						return
					}

					// Handle all position types else just push to the end
					if (!position) {
						items.push(task)
					} else if ('index' in position) {
						items.splice(position.index, 0, task)
					} else if ('childSortOrder' in position) {
						draft.items = sortBy(items, (task) =>
							position.childSortOrder.indexOf(task.id)
						)
					}
				})

			const addTaskToInfiniteData = (
				task: Task,
				position?: TaskPosition
			) =>
				produce((draft) => {
					if (!draft?.pages[0]) {
						draft.pages[0] = createEmptyPagePaginatedResult<Task>()
					}

					draft.pages[0] = addTaskToApiListResult(
						task,
						position
					)(draft.pages[0])
				})

			if (isApiListResult<Task>(data)) {
				queryClient.setQueryData(
					queryKey,
					addTaskToApiListResult(task, position)
				)
			} else if (
				isInfiniteData<ApiListResult<Task>>(data) &&
				isApiListResult<Task>(data.pages[0])
			) {
				queryClient.setQueryData(
					queryKey,
					addTaskToInfiniteData(task, position)
				)
			}
		}

		// Check if task should be in the selected project and add the new task
		// to the query cache then rebuild the tree.
		const selectedProject = get().projects.selected
		const tree = selectedProject.tree as Tree<TreeTask>

		if (!task.parentId && position?.parentId) {
			task.parentId = position.parentId
		}

		if (
			selectedProject &&
			selectedProject.projectId &&
			(isDescendantOf(selectedProject.projectId, task) ||
				(tree && isParentInTree(tree, task)))
		) {
			const inactiveTasksRange = getInactiveTasksRange(get().queryClient)
			const inactiveFrom = getInactiveFrom(inactiveTasksRange)
			const projectDetail = queryClient.getQueryData<ProjectDetail>(
				projectKeys.detail(selectedProject.projectId, { inactiveFrom })
			)
			// Only call setQueryData if the project is in the cache, else it will save and cache the value as undefined
			if (projectDetail) {
				queryClient.setQueryData<ProjectDetail | undefined>(
					projectKeys.detail(selectedProject.projectId, {
						inactiveFrom,
					}),
					produce((draft) => {
						if (
							draft &&
							draft.descendants &&
							!draft.descendants.includes(task.id)
						) {
							draft.descendants.push(task.id)
						}
					})
				)
			}
			if (task.parentId) {
				queryClient.setQueryData<Task | undefined>(
					taskKeys.detail(task.parentId),
					(prevData) => {
						if (!prevData) {
							return undefined
						}

						const childSortOrder = position?.childSortOrder
						const index = position?.index
						if (index) {
							return produce(prevData, (draft) => {
								draft.childSortOrder =
									draft.childSortOrder || []
								draft.childSortOrder.splice(index, 0, task.id)
							})
						} else if (childSortOrder) {
							return produce(prevData, (draft) => {
								draft.childSortOrder = childSortOrder
							})
						} else {
							return prevData
						}
					}
				)
			}

			// This is repeated code, but solves a race condition where
			// rebuildTree might run before the task has been saved from another
			// async action.
			const oldTask = queryClient.getQueryData<Task>(
				taskKeys.detail(task.id)
			)
			if (oldTask) {
				queryClient.setQueryData(
					taskKeys.detail(task.id),
					mergeDeepLeft(task, oldTask)
				)
			} else {
				queryClient.setQueryData(taskKeys.detail(task.id), task)
			}
			rebuildTree(set)
		}
	}

const createRemoveTaskFromState = (set: MutatedSetState) => (taskId: string) =>
	set((draft) => {
		// Check if task is in the overview of projects
		const overview = draft.projects.overview
		overview.starred = overview.starred.filter((id) => id !== taskId)
		overview.owned = overview.owned.filter((id) => id !== taskId)
		overview.shared = overview.shared.filter((id) => id !== taskId)

		// Check if task is in the selected project and remove from tree
		const tree = draft.projects.selected.tree as Tree<TreeTask>
		const prevTask = tree.items[taskId]
		if (tree.rootId !== taskId && prevTask) {
			delete tree.items[taskId]

			if (prevTask.data.parentId) {
				const parent = tree.items[prevTask.data.parentId]
				if (parent) {
					parent.children.splice(indexOf(taskId, parent.children), 1)
					parent.data.childSortOrder.splice(
						indexOf(taskId, parent.data.childSortOrder),
						1
					)
				}
			}
		}
	})

const createMoveTaskOnTree =
	(set: MutatedSetState) =>
	(taskId: string, destination: TaskPosition, source: TaskPosition) => {
		// Assume the referenced task exists in tree
		set((draft) => {
			const tree = draft.projects.selected.tree as Tree<TreeTask>

			const item = tree.items[taskId]
			if (item) {
				const srcParentTreeTask = source.parentId
					? tree.items[source.parentId]
					: null
				if (srcParentTreeTask) {
					srcParentTreeTask.children =
						srcParentTreeTask.children.filter((id) => id !== taskId)
					srcParentTreeTask.data.childSortOrder =
						srcParentTreeTask.children
					if (srcParentTreeTask.children.length === 0) {
						srcParentTreeTask.hasChildren = false
					}
				}
			}

			const destParentTreeTask = destination.parentId
				? tree.items[destination.parentId]
				: null
			if (destParentTreeTask) {
				// Moved within tree

				if (typeof destination?.index === 'number') {
					destParentTreeTask.children.splice(
						destination.index,
						0,
						taskId
					)
				} else if (destination?.childSortOrder) {
					destParentTreeTask.children = destination.childSortOrder
				}
				destParentTreeTask.data.childSortOrder =
					destParentTreeTask.children
			} else {
				// Moved out of tree
				delete tree.items[taskId]
			}

			if (!item) {
				// The the item doesn't exist, we can't do any updates on it.
				// TODO: add item to the tree somehow
				return
			}

			item.data.parentId = destination.parentId
			// TODO: Improve this. Updating the path may have to displace
			// another item
			item.path.pop()
			if (destination.index != null) {
				item.path.push(destination.index)
			}
		})
	}

const toggleTreeNode = (
	set: MutatedSetState,
	get: GetState<AppState>,
	taskId: string,
	isExpanded: boolean
) => {
	get().updateTask(taskId, { isMinimised: !isExpanded })
	set((draft) => {
		const tree = draft.projects.selected.tree as Tree<TreeTask>
		const treeItem = tree.items[taskId]

		if (treeItem) {
			treeItem.isExpanded = isExpanded
			if (isParentTreeTask(treeItem.data)) {
				treeItem.data.isMinimised = !isExpanded
			}
		}
	})
}

const emptyTree = { rootId: null, items: {} }

export interface ProjectsSlice extends Slice, SliceFilterActions {
	init: (forceBoard?: BoardView) => TeardownFn
	board: {
		view: string
	}
	// Tasks that are considered projects
	overview: {
		owned: string[]
		recent: string[]
		shared: string[]
		starred: string[]
	}
	selected: {
		filter: {
			assignee: Filter[]
			owner: Filter[]
			priority: Filter[]
			search: Filter[]
			selected: Filter[]
			status: Filter[]
			tags: Filter[]
			workflow: Filter[]
		}
		isLoading: boolean
		projectId: string | null
		tree: Tree<TreeTask | WorkflowStep>
		usersFocus: ObjectIndex<UserFocus>
	}
	view: string
	refetch: () => void
	setProject: (projectId: string) => void
	setProjectView: (view: ProjectView) => void
	setBoardView: (view: BoardView) => void
	setInactiveTasksRange: (range: InactiveTasksRange) => void
	collapseTreeNode: (taskId: string) => void
	expandTreeNode: (taskId: string) => void
	toggleExpandAll: (isExpanded: boolean) => void
	toggleExpandTreeNode: (taskId: string, isExpanded: boolean) => void
}

export const createProjectsSlice: MutatedAppState<ProjectsSlice> = (
	set,
	get,
	api
) => ({
	board: {
		view: BOARD_VIEW.PARENT,
	},
	// TODO: move out of core state, cursors are a web only thing
	// liveCursors: {},
	overview: {
		owned: [],
		recent: [],
		shared: [],
		starred: [],
	},
	selected: {
		filter: {
			assignee: [],
			owner: [],
			priority: [],
			search: [],
			selected: [],
			status: [],
			tags: [],
			workflow: [],
		},
		isLoading: true,
		projectId: null,
		tree: emptyTree,
		usersFocus: {},
	},
	view: 'board',
	init: (forceBoard) => {
		const apiAdapter$ = getServiceFromState(api, 'apiAdapter')
		// const queryClient$ = getServiceFromState(api, 'queryClient')
		const socket$ = getServiceFromState(api, 'socket')

		const { apiAdapter, queryClient } = get()

		// bind to server state
		// bind to sockets
		// should run on each render

		const usersFocus$ = createUsersFocusObservable(api)
		const usersFocusSubscription = usersFocus$.subscribe((focus) => {
			set((draft) => {
				draft.projects.selected.usersFocus = focus
			})
		})

		const projectDetailQuery = createProjectDetailQuery(
			apiAdapter,
			queryClient
		)
		const taskDetailQuery = createTaskDetailQuery(apiAdapter, queryClient)

		const addTaskToProjects = createAddTaskToProjects(set, get)
		const moveTaskOnTree = createMoveTaskOnTree(set)
		const removeTaskFromState = createRemoveTaskFromState(set)
		const removeTaskFromQueryCache =
			createRemoveTaskFromQueryCache(queryClient)

		const handleAddTaskAction = ({
			position,
			task,
		}: {
			position?: TaskPosition
			task: Task
		}) => {
			addTaskToProjects(task, position)
		}

		const handleMoveTaskAction = ({
			taskId,
			destination,
			source,
		}: MoveTaskAction) => {
			moveTaskOnTree(taskId, destination, source)
		}

		const handleRemoveTaskAction = (taskId: string) => {
			removeTaskFromState(taskId)
			removeTaskFromQueryCache(taskId)
			// TODO: fill in remove logic
		}

		const handleUpdateTaskAction = ({
			taskId,
			changes,
		}: UpdateTaskAction) => {
			if (
				isInactive(changes.statusCode) &&
				// TODO: improve this by comparing donedate/deletedate to range.
				// This is unlikely to ever come up here, but is good for
				// redundancy and to catch some possible edge cases.
				getInactiveTasksRange(get().queryClient) === 'none'
			) {
				// TODO: filter out of tree based on inactiveTasksRange
				removeTaskFromState(taskId)
			}

			if (changes.workflowData) {
				// TODO: move this into a reusable function
				set((draft) => {
					// Handle moving board tasks when workflow steps change, but
					// only on the workflow board.
					if (draft.projects.board.view !== 'workflow') {
						return
					}

					const tree = draft.projects.selected.tree

					if (
						!tree.rootId ||
						!tree.items[tree.rootId] ||
						!changes.workflowData
					) {
						return
					}

					// Remove current task from step
					const stepId = find((stepId) => {
						const children = tree.items?.[stepId]?.children || []
						return includes(taskId, children)
					}, tree.items[tree.rootId].children)
					if (stepId && tree.items[stepId]) {
						const step = tree.items[stepId]
						step.children = step.children.filter(
							(id) => id !== taskId
						)
					}

					// Add task to active step
					const activeStepId = changes.workflowData.activeStepId
					const newStep = activeStepId && tree.items[activeStepId]
					if (newStep) {
						newStep.children.unshift(taskId)
					}
				})
			}
		}

		// Actions triggered by a user interaction
		const addTaskSubscription =
			addTaskSubject.subscribe(handleAddTaskAction)
		const moveTaskSubscription =
			moveTaskSubject.subscribe(handleMoveTaskAction)
		const removeTaskSubscription = removeTaskSubject.subscribe(
			handleRemoveTaskAction
		)
		const updateTaskSubscription = updateTaskSubject.subscribe(
			handleUpdateTaskAction
		)

		// Actions triggered by the API
		const taskMove$ = socket$.pipe(
			switchMap((socket) => createTaskMoveSocket(socket))
		)
		const moveTaskSocketSubscription = taskMove$.subscribe(
			({ destParent, srcParent, task }) =>
				handleMoveTaskAction({
					taskId: task.id,
					destination: destParent
						? {
								parentId: destParent.id,
								childSortOrder: destParent.childSortOrder,
							}
						: { parentId: null },
					source: srcParent
						? {
								parentId: srcParent.id,
								childSortOrder: srcParent.childSortOrder,
							}
						: { parentId: null },
				})
		)

		const taskUpdate$ = socket$.pipe(
			switchMap((socket) => createTaskUpdateSocket(socket))
		)
		const updateTaskSocketSubscription = taskUpdate$.subscribe((task) => {
			handleUpdateTaskAction({
				taskId: task.id,
				changes: omit(['id'], task),
			})
		})

		// Store changes
		const rebuildTreeListener = new Subject()

		const projectId = get().projects.selected.projectId
		const projectIdSubject = new BehaviorSubject({
			state: projectId,
			prevState: projectId,
		})
		const projectId$ = connectable(
			createZustandObservable(
				api,
				(state) => state.projects.selected.projectId,
				{ fireImmediately: true }
			).pipe(tap(() => rebuildTreeListener.next(0))),
			{ connector: () => projectIdSubject, resetOnDisconnect: false }
		)
		projectId$.connect()

		// Throttle the rebuilding of the tree so we don't do it too often
		rebuildTreeListener
			.pipe(
				throttleTime(200, undefined, { leading: true, trailing: true }),
				tap(() => {
					rebuildTree(set)
				})
			)
			.subscribe()

		const playerOptions$ = apiAdapter$.pipe(
			switchMap((apiAdapter) =>
				createQueryObservable(queryClient, {
					queryKey: playerKeys.options(),
					queryFn: fetchPlayerOptions(apiAdapter, {
						optionIds: userOptionIds,
					}),
				})
			)
		)
		const boardView$ = playerOptions$.pipe(
			rxjsFilter(({ data }) =>
				Boolean(data && data['projects.boardView'])
			),
			rxjsMap(({ data }) =>
				data && data['projects.boardView']
					? (data['projects.boardView'] as string)
					: null
			)
		)
		type ProjectViews = Pick<
			PlayerOptions,
			'projects.defaultView' | 'projects.boardView'
		>
		const projectViews$ = playerOptions$.pipe(
			rxjsMap(({ data }) => data),
			// Filter only when both values are truthy
			rxjsFilter((data): data is ProjectViews =>
				Boolean(
					data &&
						data['projects.defaultView'] &&
						data['projects.boardView']
				)
			),
			rxjsMap((data) => ({
				boardView: data['projects.boardView'],
				projectView: data['projects.defaultView'],
			}))
		)

		// Save any player option changes to state
		projectViews$.subscribe(({ boardView, projectView }) => {
			set((draft) => {
				draft.projects.view = projectView
				draft.projects.board.view =
					forceBoard || getBoardView(draft, boardView)
			})
			rebuildTreeListener.next(0)
		})

		const project$ = projectId$.pipe(
			rxjsMap(({ state }) => state),
			distinctUntilChanged(),
			tap(() => {
				// Empty the tree when projectId changes
				set((draft) => {
					draft.projects.selected.tree = emptyTree
				})
			}),
			switchMap((projectId) =>
				createQueryObservable(queryClient, taskDetailQuery(projectId))
			),
			combineLatestWith(boardView$)
		)

		const inactiveTasksRange$ = merge(
			// Create an initial black default for pairwise so we can detect a change
			of('' as InactiveTasksRange),
			playerOptions$.pipe(
				rxjsMap(({ data }) =>
					data && data[optionIds.INACTIVE_TASKS_RANGE_KEY]
						? (data[
								optionIds.INACTIVE_TASKS_RANGE_KEY
							] as InactiveTasksRange)
						: null
				)
			)
		).pipe(
			pairwise(),
			rxjsFilter(([prev, next]) => prev !== next),
			rxjsMap(([, next]) => next)
		)

		const projectSettings$ = combineLatest([
			projectId$,
			inactiveTasksRange$,
		]).pipe(
			rxjsMap(([projectIdResult, inactiveRange]) => {
				const { state: projectId } = projectIdResult
				const inactiveFrom = inactiveRange
					? getInactiveFrom(inactiveRange)
					: null
				return [projectId, inactiveFrom]
			}),
			rxjsFilter((data): data is [string, InactiveTasksRange] => {
				const [projectId, inactiveFrom] = data
				if (!projectId || !inactiveFrom) {
					return false
				}
				return true
			})
		)

		const projectStructureObservable$ = projectSettings$.pipe(
			switchMap(([projectId, inactiveFrom]) =>
				createQueryObservable(
					queryClient,
					projectDetailQuery(projectId, inactiveFrom)
				)
			)
		)

		const projectSubscription = project$.subscribe(([, boardView]) => {
			set((draft) => {
				draft.projects.board.view =
					forceBoard || getBoardView(draft, boardView)
			})
			rebuildTreeListener.next(0)
		})

		// const projectSettingsSubscription = projectSettings$
		// 	.pipe(
		// 		distinctUntilChanged(
		// 			([prevId, prevInactiveFrom], [nextId, nextInactiveFrom]) =>
		// 				prevId === nextId &&
		// 				prevInactiveFrom === nextInactiveFrom
		// 		)
		// 	)
		// 	.subscribe(() => {
		// 		set((draft) => {
		// 			draft.projects.selected.isLoading = true
		// 		})
		// 	})

		const projectStructureObservableSubscription =
			projectStructureObservable$.subscribe((result) => {
				if (!result.data) {
					setTimeout(() => {
						set((draft) => {
							draft.projects.selected.isLoading = result.isLoading
						})
					})
					return
				}

				const data = result.data as ProjectDetail

				rebuildTreeListener.next(0)
				requestAnimationFrame(() => {
					rebuildFilters(set, get, data?.descendants || [])
				})

				setTimeout(() => {
					set((draft) => {
						draft.projects.selected.isLoading = result.isLoading
					})
				})
			})

		// Rebuild tree whenever a updateTask action is triggered
		const rebuildTreeOnTaskUpdate$ = updateTaskSubject.subscribe(() =>
			rebuildTreeListener.next(0)
		)

		return () => {
			moveTaskSocketSubscription?.unsubscribe()
			updateTaskSocketSubscription?.unsubscribe()
			usersFocusSubscription?.unsubscribe()

			addTaskSubscription.unsubscribe()
			moveTaskSubscription.unsubscribe()
			removeTaskSubscription.unsubscribe()
			updateTaskSubscription.unsubscribe()

			projectSubscription.unsubscribe()
			//projectSettingsSubscription.unsubscribe()
			projectStructureObservableSubscription.unsubscribe()
			rebuildTreeOnTaskUpdate$.unsubscribe()
		}
	},
	refetch: async () => {
		const projectId = get().projects.selected.projectId
		// We can't refetch anything if a user isn't selected
		if (!projectId) {
			return
		}

		const projectDetailQuery = createProjectDetailQuery(
			get().apiAdapter,
			get().queryClient
		)
		get().queryClient.fetchQuery({
			...projectDetailQuery(projectId),
			// Force a refetch even if data is not stale
			staleTime: 0,
		})
	},
	setProject: async (projectId: string) => {
		get().projects.clearFilters()
		set((draft) => {
			draft.projects.selected.projectId = projectId
		})
	},
	setProjectView: (view) => {
		const prevView = get().projects.view
		if (prevView === view) {
			return
		}
		set((draft) => {
			draft.projects.view = view
		})
		get().player.updateOptions({ 'projects.defaultView': view })
	},

	// Board actions
	setBoardView: (view) => {
		set((draft) => {
			draft.projects.board.view = getBoardView(draft)
		})
		get().player.updateOptions({ 'projects.boardView': view })
	},

	// Filter actions
	addFilter: (filter) => {
		if (!filter) {
			return
		}
		set((draft) => {
			const currentFilters = draft.projects.selected.filter.selected
			const filters = uniqBy(
				(filter) => [filter.id, filter.type],
				[filter, ...currentFilters]
			)
			if (!isDeepEqual(currentFilters, filters)) {
				draft.projects.selected.filter.selected = filters
			}
		})
		rebuildTree(set)
	},
	clearFilters: () => {
		set((draft) => {
			draft.projects.selected.filter.assignee = []
			draft.projects.selected.filter.owner = []
			draft.projects.selected.filter.priority = []
			draft.projects.selected.filter.search = []
			draft.projects.selected.filter.selected = []
			draft.projects.selected.filter.status = []
			draft.projects.selected.filter.tags = []
			draft.projects.selected.filter.workflow = []
		})
	},
	removeFilter: (filterType, filterId) => {
		if (!filterType) {
			return
		}
		set((draft) => {
			const currentFilters = draft.projects.selected.filter.selected
			const wherCond = filterId
				? where({
						id: isDeepEqual(filterId),
						type: isDeepEqual(filterType),
					})
				: where({ type: isDeepEqual(filterType) })
			const filters = reject(wherCond, currentFilters)
			if (!isDeepEqual(currentFilters, filters)) {
				draft.projects.selected.filter.selected = filters
			}
		})
		rebuildTree(set)
	},
	setInactiveTasksRange: (range: InactiveTasksRange) => {
		get().player.updateOptions({
			[optionIds.INACTIVE_TASKS_RANGE_KEY]: range,
		})
		setTimeout(() => {
			rebuildTree(set)
		})
	},

	// Tree actions
	collapseTreeNode: (taskId) => {
		toggleTreeNode(set, get, taskId, false)
	},
	expandTreeNode: (taskId) => {
		toggleTreeNode(set, get, taskId, true)
	},
	toggleExpandAll: (isExpanded) => {
		set((draft) => {
			const tree = draft.projects.selected.tree as Tree<TreeTask>
			forEachObjIndexed((treeItem) => {
				treeItem.isExpanded = isExpanded
				treeItem.data.isMinimised = !isExpanded
			}, tree.items)
		})
	},
	toggleExpandTreeNode: (taskId, isExpanded) => {
		toggleTreeNode(set, get, taskId, isExpanded)
	},
})
